import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga4';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table'
import mqtt from "mqtt/dist/mqtt";
import {decodeToken} from 'react-jwt';
import './App.css';
import {Humidity, Temperature} from './widgets';
import {subscribe_to_push} from './push_notifications/utils';

const user_info_url = "https://us-east1-remote-farm-proj.cloudfunctions.net/info_usuario";
const register_push_url = "https://us-central1-remote-farm-proj.cloudfunctions.net/register-push-endpoint";
const AWS_IOT_ENDPOINT = "wss://d08303381btncu1qajgah-ats.iot.sa-east-1.amazonaws.com";

const FARM_STATUS = {
  CONNECTING: 1,
  CONNECTED: 2,
  DISCONNECTED: 3
}

const TAG_STRING_CONVERSION = [
  {last_update: "Atualizado em"},
  {alarm_on: "Alarme"},
  {avg_temp: "Temperatura Média"},
  {tgt_temp: "Temperatura Desejada"},
  {out_temp: "Temperatura Externa"},
  {in_air_rh: "Umidade do Ar"},
  {tgt_rh: "Umidade Desejada"},
  {heaters_on: "Fornalha"},
  {cooling_on: "Cooling"},
  {foggers_on: "Nebulizador"},
  {cooling_temp: "Temperatura Resfriamento"},
  {heating_temp: "Temperatura Aquecimento"},
  {static_press: "Pressão Estática"},
  {tgt_press: "Pressão Desejada"},
  {vent_level: "Nível de Ventilação"},
  {vent_mode: "Modo de Ventilação"},
  {daily_water: "Consumo de Água no Dia"},
  {growth_day: "Dia do Lote"},
  {flock_number: "Número do Lote"}
]

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      farm_connection: FARM_STATUS.CONNECTING,
      farm_parameters: {},
      internal_temp: null,
      external_temp: null,
      internal_humidity: null,
      heating_temp: null,
      cooling_temp: null,
      daily_water: null,
      show_modal_notification: false,
    }

    this.farm_last_update = null;
    this.check_connection_interval = null;

    this.session_token = window.localStorage.getItem('sessionToken');
    if (this.session_token === null)
      window.location.reload();

    this.check_connection = this.check_connection.bind(this);
  }

  componentDidMount() {
    let deferredPrompt;
    const addBtn = document.querySelector('#add-button');
    addBtn.style.display = 'none';

    ReactGA.event({
      category: 'user',
      action: "Component did mount"
    });

    // Register service worker to control making site work offline
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker.register(swUrl)
        .then((swReg) => {

          swReg.addEventListener('updatefound', () => {
            const newWorker = swReg.installing;
            var refreshing;

            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'activated') {
                if (refreshing) return;
                // alert('Uma atualização foi encontrada e será instalada automaticamente!');

                ReactGA.event({
                  category: 'app_update',
                  action: this.props.version
                });

                window.location.reload();
                refreshing = true;
              }
            })
          });

          window.mySwRegister = swReg;

          subscribe_to_push(swReg).then(subscriptionStatus => {
            const send_obj = {
              token: this.session_token,
              push_options: subscriptionStatus.currentSubscription.toJSON(),
            };

            fetch(register_push_url, {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(send_obj),
            }).then(res => {
              console.log("subscription res:", res.ok);
              if (res.ok) {
                return res.json();
              } else
                return Promise.reject(res);
            }).then(res_json => {
              window.localStorage.setItem('sessionToken', res_json.token);
              this.session_token = res_json.token;
            }).catch(err => {
              console.error("Error registering push subscription information:", err);
            });
          });
        });
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = '';

      addBtn.addEventListener('click', (e) => {
        this.requestNotificationPermission();

        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            ReactGA.event({
              category: 'A2HS',
              action: 'Accepted'
            });
            addBtn.style.display = 'none';
          } else {
            ReactGA.event({
              category: 'A2HS',
              action: 'Declined'
            });
            addBtn.style.display = '';
          }
          deferredPrompt = null;
        });
      });
    });

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') { // openning app that was already running
        ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});

        if (window.mySwRegister !== undefined) {
          window.mySwRegister.update(); // check for service worker update

          if (window.mySwRegister.waiting && window.mySwRegister.active) {
            window.mySwRegister.waiting.postMessage({type: 'SKIP_WAITING'});
          }
        }
      }
    });

    // iOS doesn't support the beforeinstallprompt event
    if (this.isIosInstalled() && Notification.permission !== "granted") {
      this.setState({show_modal_notification: true});
    }
    this.connect_mqtt();

    fetch(user_info_url + '?' + (new URLSearchParams({
      token: this.session_token
    })).toString()).then(res => {
      if (res.ok) {
        return res.json();
      } else
        return Promise.reject(res.status);
    }).then(res => {
      this.session_token = res.token;
      window.localStorage.setItem('sessionToken', res.token);
      console.log("Hello", res.first_name, res.last_name);
    }).catch(err => {
      console.error("error:", err);
    });
  }

  componentWillUnmount() {
    if (this.check_connection_interval !== null) {
      clearInterval(this.check_connection_interval);
      this.check_connection_interval = null;
    }
  }

  connect_mqtt() {
    let mqttUrl = "wss://api.meuaviario.com.br";
    let topicPrefix = "";

    const myDecodedToken = decodeToken(this.session_token);

    if (myDecodedToken.hasOwnProperty("farms")) {
      const farm = myDecodedToken.farms[0];
      mqttUrl = AWS_IOT_ENDPOINT;
      topicPrefix = `remote_farm/${farm}/`;
    }

    let mqtt_client = mqtt.connect(mqttUrl, {
      username: "auth",
      password: this.session_token,
      reconnectPeriod: 2_500,
      connectTimeout: 10_000,
      // keepalive: 75_000,
      clientId: myDecodedToken.device_id.replace(/-/g, ''),
    });

    mqtt_client.on('connect', () => {
      for (let i = 0; i < TAG_STRING_CONVERSION.length; i += 7) {
        const topics = TAG_STRING_CONVERSION.slice(i, i + 7).map((obj) => topicPrefix + Object.keys(obj)[0]);
        mqtt_client.subscribe(topics, {qos: 1}, (err, granted) => {
          if (err === null) { // success
            // this.check_connection();

            if (this.check_connection_interval === null)
              this.check_connection_interval = setInterval(this.check_connection, 3000);
          } else {
            ReactGA.event({
              category: 'Exception',
              action: 'Subscribe error'
            });
          }
        });
      }
    });

    mqtt_client.on('message', (topic, message, packet) => {
      let decodedMsg = "";
      switch (topic) {
        case topicPrefix + 'alarm_on':
        case topicPrefix + 'cooling_on':
        case topicPrefix + 'foggers_on':
        case topicPrefix + 'heaters_on':
          if (parseInt(message.toString()) === 0)
            decodedMsg = "OFF";
          else
            decodedMsg = "ON";
          break;

        case topicPrefix + "last_update":
          let last_updated = new Date(message.toString());
          // not compatible with Safari
          // value = last_updated.toLocaleString('pt-BR', {dateStyle: 'short', timeStyle: 'short'});

          // workaround
          let date = last_updated.toLocaleString('pt-BR', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          });
          let hour = last_updated.toLocaleTimeString('pt-BR', {hour: '2-digit', minute: '2-digit'});

          decodedMsg = date + " " + hour;
          this.farm_last_update = last_updated;
          this.check_connection();
          break;

        case topicPrefix + 'vent_mode':
          if (message.toString() === 'Min Vent')
            decodedMsg = "Ventilação Mínima";
          else if (message.toString() === 'Tunnel')
            decodedMsg = "Túnel";
          else
            decodedMsg = message.toString();
          break;

        case topicPrefix + 'avg_temp':
          const avg_temp = message.toString();
          this.setState({internal_temp: avg_temp});
          decodedMsg = message.toString() + "ºC";
          break;

        case topicPrefix + 'out_temp':
          const ext_temp = message.toString();
          this.setState({external_temp: ext_temp});
          decodedMsg = ext_temp + "ºC";
          break;

        case topicPrefix + 'tgt_temp':
          decodedMsg = message.toString() + "ºC";
          break;

        case topicPrefix + 'cooling_temp':
          const cooling_temp = message.toString();
          this.setState({cooling_temp: cooling_temp});
          decodedMsg = cooling_temp + "ºC";
          break;

        case topicPrefix + 'heating_temp':
          const heating_temp = message.toString();
          this.setState({heating_temp: heating_temp});
          decodedMsg = heating_temp + "ºC";
          break;

        case topicPrefix + 'in_air_rh':
          const internal_hum = message.toString();
          this.setState({internal_humidity: internal_hum});
          decodedMsg = internal_hum + "%";
          break;
        case topicPrefix + 'tgt_rh':
          decodedMsg = message.toString() + "%";
          break;

        case topicPrefix + 'daily_water':
          const dailyWater = message.toString();
          this.setState({daily_water: dailyWater});
          decodedMsg = dailyWater + "L";
          break;

        default:
          decodedMsg = message.toString();
      }

      let old_obj = this.state.farm_parameters;
      old_obj[topic.replace(topicPrefix, "")] = decodedMsg;

      this.setState({farm_parameters: old_obj});
      // console.log('message received on topic', topic, decodedMsg);
    });

    mqtt_client.on('close', () => {
      this.setState({
        farm_connection: FARM_STATUS.CONNECTING
      });

      // connection lost - stop updating status
      if (this.check_connection_interval !== null) {
        clearInterval(this.check_connection_interval);
        this.check_connection_interval = null;
      }
    });

    // mqtt_client.on('reconnect', () => {
    //   // console.log("reconnect");
    // this.setState({farm_connection: FARM_STATUS.CONNECTING});
    // });
  }

  check_connection() {
    // console.log("check_connection");
    if (this.farm_last_update === null)
      return;
    if ((new Date() - this.farm_last_update) / 60000 > 5) { // 5 minutes
      // not connected
      this.setState({farm_connection: FARM_STATUS.DISCONNECTED});
    } else {
      this.setState({farm_connection: FARM_STATUS.CONNECTED});
    }
  }

  connection_badge() {
    switch (this.state.farm_connection) {
      case FARM_STATUS.CONNECTING:
        return <Badge pill bg="secondary" className="normal-font-size">
          <Spinner animation="grow" size="sm" style={{verticalAlign: "unset"}}/> Conectando...</Badge>;
      case FARM_STATUS.CONNECTED:
        return <Badge pill bg="success" className="normal-font-size">Aviário Conectado</Badge>;
      default:
        return <Badge pill bg="danger" className="normal-font-size justify-content-end mt-2 mr-0">Sem
          Conexão</Badge>;
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Container fluid>
            <Row>
              <Button id="add-button" className="normal-font-size" variant="primary" size="lg" block>Salvar
                na Tela</Button>
            </Row>
            <Row>
              <Col className="justify-content-end mt-2 me-0" style={{display: "flex"}}>
                {this.connection_badge()}
              </Col>
            </Row>
          </Container>

          <Row>
            <Col><Temperature name="Temp. Interna" value={this.state.internal_temp}
                              redLine={this.state.heating_temp} blueLine={this.state.cooling_temp}/></Col>
            <Col><Temperature name="Temp. Externa" value={this.state.external_temp}/></Col>
          </Row>
          <Row>
            <Col><Humidity current_value={this.state.internal_humidity} name="Umidade do Ar"
                           size="5em"/></Col>
            <Col><Humidity current_value={this.state.daily_water} name="Água (dia)" size="5em"
                           unit="L"/></Col>
          </Row>
          <p/>

          <Table striped bordered size="sm">
            <tbody>
            {TAG_STRING_CONVERSION.map((obj, idx) => {
              let tag_name = Object.keys(obj)[0];
              return (
                <tr className="normal-font-size" key={idx}>
                  <td>{obj[tag_name]}</td>
                  <td>{this.boldSkipFirst(idx, this.state.farm_parameters[tag_name])}</td>
                </tr>
              );
            })}
            </tbody>
          </Table>

          <div className="App-version small-font-size">
            {this.props.version}
          </div>
        </header>

        <Modal show={this.state.show_modal_notification}
               onHide={() => this.setState({show_modal_notification: false})}>
          <Modal.Header closeButton>
            <Modal.Title>Habilitar Notificações</Modal.Title>
          </Modal.Header>
          <Modal.Body>Habilite notificações para receber alertas de alarme ativo!</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => {
              this.setState({show_modal_notification: false});
              this.requestNotificationPermission();
            }}>
              Habilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  boldSkipFirst(idx, content) {
    if (idx > 0)
      return <b>{content}</b>;
    else
      return content;
  }

  isIosInstalled() {
    return /^(iPhone|iPad|iPod)/.test(navigator.platform) && window.navigator.standalone;
  }

  requestNotificationPermission() {
    // swReg.pushManager.getSubscription().then((subscription) => {
    //   console.log("subs", subscription);
    // });

    if (Notification.permission !== "granted") {
      Notification.requestPermission((result) => {
        ReactGA.event({
          category: 'NotificationPermission',
          action: result
        });
      });
    }
  }
}

export default App;
